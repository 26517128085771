import styled from "@emotion/styled"

import { wrapper, link } from "../../styles/shared.styles"
import { mq } from "../../styles/helpers.styles"
import { fixedValues, fonts, colors } from "../../styles/variables.styles"
import { css } from "@emotion/core"

export const Wrapper = styled.article`
  ${wrapper}
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  padding-top: ${`calc(${fixedValues.headerHeightMobile} + 5vh)`};
  ${mq.tablet} {
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
  }
  ${mq.desktop} {
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
  }
  ${mq.desktop__l} {
    padding-top: ${`calc(${fixedValues.largeHeaderHeight} + 5vh)`};
  }
`
export const Header = styled.header`
  max-width: 980px;
  margin: 0 auto 6em;
  ${mq.desktop__l} {
    max-width: 1280px;
    margin: 0 auto 8em;
  }
`

const blog_base_typo = css`
  font-size: 1.6rem;
  line-height: 1.5em;
  ${mq.tablet} {
    font-size: 2rem;
  }
  ${mq.desktop} {
    font-size: 2.2rem;
  }
  ${mq.desktop__l} {
    font-size: 2.8rem;
  }
`

const blog_headline_2 = css`
  font-family: ${fonts.title};
  font-size: 2em;
  line-height: 1.2em;
  letter-spacing: 0.015em;
  margin: 2em 0 1em;
  ${mq.desktop} {
    font-size: 2.2em;
  }
`

const blog_headline_3 = css`
  font-family: ${fonts.title};
  font-size: 1.6em;
  line-height: 1em;
  letter-spacing: 0.015em;
  margin: 2em 0 1em;
`

const blog_blockquote = css`
  font-size: 1.2em;
  line-height: 1.25em;
`

export const MainTitle = styled.h1`
  font-family: ${fonts.title};
  font-size: 2.8em;
  line-height: 1.35em;
  letter-spacing: 0.025em;

  ${mq.tablet} {
    line-height: 1.2em;
    font-size: 4.8em;
  }
  ${mq.desktop} {
    font-size: 7.2em;
  }
  ${mq.desktop__l} {
    font-size: 8.2em;
  }
`

export const MainBody = styled.div`
  ${blog_base_typo}
  > * {
    max-width: 980px;
    margin: 0 auto;
    ${mq.desktop__l} {
      max-width: 1280px;
    }
  }
  ul {
    list-style: initial;
  }
  ul,
  ol {
    padding: 0;
  }
  p {
    margin: 1.5em 0;
  }
  h2 {
    ${blog_headline_2}
  }
  h3 {
    ${blog_headline_3}
  }
  strong {
    font-family: ${fonts.bold};
  }
  a {
    ${link}
  }
  blockquote {
    position: relative;
    color: ${colors.primary.blue};
    margin: 2em 1.5em;
    ${blog_blockquote}
    &:before {
      content: '"';
      position: absolute;
      font-size: 2em;
      left: 0;
      transform: translateX(-0.75em);
    }
    &:after {
      display: inline-block;
      position: relative;
      content: '"';
      transform: translate(0.25em, 0.25em);
      font-size: 2em;
      line-height: 0.35em;
    }
  }
`

const meta_typo = css`
  font-size: 1.4em;
  ${mq.desktop} {
    font-size: 2em;
  }
  ${mq.desktop__l} {
    font-size: 2.2em;
  }
`

export const Category = styled.div`
  ${meta_typo}
  font-family: ${fonts.bold};
  margin-bottom: 0.5em;
`

export const PublishDate = styled.div`
  ${meta_typo}
  margin-top: 0.5em;
`

export const MainImage = styled.div`
  margin-bottom: 4em;
  ${mq.desktop__l} {
    margin-bottom: 8em;
  }
`

const nextPost_font_sizes = css`
  font-size: 1.9em;
  line-height: 1.2em;
  ${mq.tablet} {
    font-size: 2.2em;
  }
  ${mq.desktop} {
    font-size: 2.6em;
  }
`

export const NextPost = styled.section`
  max-width: 980px;
  margin: 7vh auto;
  .headline-wrapper {
    ${blog_base_typo}
  }
  .next-label {
    ${nextPost_font_sizes}
  }
  a:hover h2 {
    color: ${colors.primary.blue};
  }
  h2 {
    ${nextPost_font_sizes}
    font-family: ${fonts.title};
    letter-spacing: 0.025em;
    display: inline;
  }
  ${mq.desktop__l} {
    max-width: 1280px;
  }
`

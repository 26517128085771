import React from "react"
import { graphql } from "gatsby"
import Details from "../components/Blog/BlogPost"

export const getBlogPost = graphql`
  query getBlogPost($slug: String) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      _rawBody(resolveReferences: { maxDepth: 10 })
      mainImage {
        ...SanityFigure
      }
      ...Post
      seo {
        ...SEO
      }
    }
    posts: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        ...Post
      }
    }
  }
`
const BlogPost = ({ data }) => {
  const post = data.post
  const posts = data.posts.nodes
  const currentPostIndex = posts.findIndex(({ _id }) => post._id === _id)
  const nextPostIndex =
    currentPostIndex + 1 === posts.length ? 0 : currentPostIndex + 1
  return <Details content={post} nextPost={posts[nextPostIndex]} />
}

export default BlogPost

import React, { useEffect } from "react"
import { format } from "date-fns"

import { useTheme } from "../../hooks/useTheme"
import PortableText from "../PortableText"
import Figure from "../Figure"

import {
  Header,
  MainBody,
  MainTitle,
  Wrapper,
  PublishDate,
  Category,
  MainImage,
  NextPost,
} from "./BlogPost.styles"
import { Link } from "gatsby"
import SEO from "../seo"

const BlogPost = ({ content, nextPost }) => {
  const { title, _rawBody, publishedAt, mainImage, category, seo } = content
  const { setTheme } = useTheme()

  useEffect(() => {
    setTheme("white")
  }, [setTheme])

  return (
    <Wrapper>
      <SEO
        {...seo}
        description={
          (seo && seo.description) || (category && category.title) || ""
        }
        title={(seo && seo.title) || title}
        image={(seo && seo.image) || mainImage}
      />
      <Header>
        {category && <Category>{category.title}</Category>}
        <MainTitle>{title}</MainTitle>
        {publishedAt && (
          <PublishDate>
            {format(new Date(publishedAt), "MM / dd / yyyy")}
          </PublishDate>
        )}
      </Header>
      {mainImage && mainImage.file && (
        <MainImage>
          <Figure {...mainImage} />
        </MainImage>
      )}

      {_rawBody && (
        <MainBody>
          <PortableText blocks={_rawBody} />
        </MainBody>
      )}
      {nextPost && (
        <NextPost>
          <Link to={`/blog/${nextPost.slug.current}`}>
            <div className="headline-wrapper">
              <span className="next-label">Read Next: </span>
              <h2 featured={nextPost.featured}>{nextPost.title}.</h2>
            </div>
          </Link>
        </NextPost>
      )}
    </Wrapper>
  )
}

export default BlogPost
